import './App.css';
import React, {useState} from 'react'
import Gallery from './components/native/gallery.jsx'
import Info from './components/native/information.jsx'
import About from './components/native/links/about.jsx'
import Services from './components/native/links/services.jsx'
import Terms from './components/native/links/terms.jsx'
import Privacy from './components/native/links/privacy.jsx'
import Name from './components/images/SprucesName.png'
import GalleryManage from './components/native/GalleryManage.jsx'
import './components/native/nav.css'

function App() {
  
  const [choice, setChoice] = useState("")

  const Nav = <div className="nav_container">
                <div className="logo">
                    <a href="https://sprucescuttingcorner.com">
                        <img className="web_name" src={Name} alt="" />
                        <p className="nav_note">"Haircuts Fades & Styles for Man Woman & Child."</p>
                        <p className="slogan_2"><span className="nav_note">1425 25th St, Newport News, VA 23607</span></p>
                        <p className="nav_note_name" >John L. Spruce - Tonsorial Specialist</p>
                        <p className="nav_note_name">Master Barber Coach Est. 1984</p>
                        <p><a className="nav_note_nnav_tel_numame " href="tel:+(757)713-3879">(757)713-4879</a></p>
                        <p className="nav_note2">Call for Appointments and Consultations</p>
                    </a>
                </div>

                <div>
                    <div className="dropdown">
                        <button className="dropbtn">Menu</button>
                        <div className="dropdown-content">
                            <button className="button-47" onClick={(e)=>{setChoice("")}}>Spruce's Cutting Corner</button>
                            <button className="button-47" onClick={(e)=>{setChoice(3)}}>Services</button>
                            <button className="button-47" onClick={(e)=>{setChoice(2)}}>About Spruce</button>
                            <button className="button-47" onClick={(e)=>{setChoice(1)}}>Contact</button>
                        </div>
                    </div>
                </div>
            </div>
  const footer = <div className="footer">
                      <p className="slogan">Haircuts Fades & Styles for Man Woman & Child.</p>
                      <div className="customer_rel">
                          <a href="https://www.instagram.com/kemeticbarber/" className="customer_rel_b i"> </a>
                          <a href="https://www.facebook.com/people/Spruces-Cutting-Corner/100064304071329/" className="customer_rel_b m"> </a>
                          <a className="customer_rel_b" onClick={(e)=>{setChoice(4)}}>Site Usage</a>
                          <a className="customer_rel_b" onClick={(e)=>{setChoice(5)}}>Privacy Policy</a>
                          <div className="">
                              <p>1425 25th St </p>
                              <p>Newport News, VA, 23607</p>
                              <br />
                              <p>(757)713-4879</p>
                          </div>
                      </div>
                      
                      <p>All Rights Reserved 2025 © Spruce's Cutting Corner LLC</p>
                  </div>


  switch(choice){
    case 1:
      return(
        <div>
          {Nav}
          <div className="main_container">
            <Info/>
            
          </div>
          {footer}
        </div>
      )
    case 2:
      return(
        <div>
          {Nav}
          <div className="main_container">
            <About/>

          </div>
          {footer}
        </div>
      )
    case 3:
      return(
        <div>
          {Nav}
          <div className="main_container">
            <Services/>

          </div>
          {footer}
        </div>
      )
    case 4:
      return(
        <div>
          {Nav}
            <div className="main_container">
              <Terms/>

            </div>
          {footer}
        </div>
      )
      case 5:
        return(
          <div>
            {Nav}
            <div className="main_container">
              <Privacy/>
  
            </div>
            {footer}
          </div>
        )
    default:
      return(
        <div>
          {Nav}
          <div className="main_container">
            <Gallery/>
            {
              //<GalleryManage/>
            }

          </div>
          {footer}
        </div>
      )
  }
  /*
  
  return(
    <div className="App body">
      <Routes>
        <Route element={<Gallery/>} path="/"/>
        <Route element={<Info/>} path="/info"/>
        <Route element={<About/>} path="/About"/>
        <Route element={<Services/>} path="/Services"/>
        <Route element={<Terms/>} path="/Terms"/>
        <Route element={<Privacy/>} path="/Privacy"/>
      </Routes>
    </div>
  )
  */
}

export default App;
