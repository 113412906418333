import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './style.css'
import Loading from './engine/logo.jsx'
import Image from '../images/IMG_3191.jpeg'
import Image1 from '../images/contact_spruce.png'

const Gallery = () =>{

    const [error, setError] = useState("")
    const [images, setImages] = useState([])
    const [img, setImg] = useState(1)
    const [loaded, setLoad] = useState("")
  
    const request = axios
    const name3 = process.env.REACT_APP_Him
    const name4 = process.env.REACT_APP_Her
    const name5 = process.env.REACT_APP_Helicopter
    const name6 = process.env.REACT_APP_umWtfAreU
    const name9 = process.env.REACT_APP_Airplane

      const front = async(e) => {

        
          try {
            e.preventDefault()
            setLoad("")
            const res = await Run2(images[images.length-1]["_id"])
            return null
          } catch (error) {
            setError("Failed Loading Image!")
            return null
            
          }
      }

      const back = async(e) =>{

        
          try {
            e.preventDefault()
            setLoad("")
            const res = await Run2(images[0]["_id"])
            return null
          } catch (error) {
            setError("Failed Loading Image!")
            return null
            
          }
      }

      const f_ward = async(e) => {

          try {
            e.preventDefault()
            if(images.length === 0){
                return null
            }
            let copy = img
            if(copy < images.length){
                copy = copy + 1
            }
            setLoad("")
            setImg(copy)
            const res = await Run2(images[copy-1]["_id"])
            
          } catch (error) {
            setError("Failed Loading Image!")
            return null
            
          }
      
      }

      const b_ward = async(e) => {
        try {
          e.preventDefault()
          if(images.length === 0){
              return null
          }
          let copy = img
          if(copy > 1){
              copy = copy - 1
          }
          setLoad("")
          setImg(copy)
          const res = await Run2(images[copy-1]["_id"])
          
        } catch (error) {
          setError("Failed Loading Image!")
          return null
          
        }
      }

    useEffect(()=>{

      Run1()

    },[])
  
    const Run1 = async() =>{
       try{
        const run = await request.post(`${process.env.REACT_APP_A234}`,{
                    [name3] : "",
                    [name4] : process.env.REACT_APP_webKey,
                    [name6] : {
                        [name9] : {
                          "data0" : process.env.REACT_APP_GT,
                          [name5] : process.env.REACT_APP_0,
                        }
                      }
                      }).then(async(R)=>{
                        if(!R.data.request){
                          setError("No Images Found!")
                          return null
                        }else{
                          const image = R.data.response
                          setImages(image)
                          const num = img
                          const selection = image[num-1]["_id"]
                          const finish = await Run2(selection)
                          return null
                        }
                      return null
                      }).catch((error)=>{
                        setError("Failed Retrieving Gallery!")
                        return null

                      })
  
        return null
       }catch(error){
          setError("Failed to Retrieve Gallery!")
          return null
       }

    }
  
      const Run2 = async(id) => {

        try{

          const run = await request.post(`${process.env.REACT_APP_A234}`,{
                      [name3] : "",
                      [name4] : process.env.REACT_APP_webKey,
                      [name6] : {
                          [name9] : {
                            "data1" : process.env.REACT_APP_GT,
                            "data0" : id,            
                            [name5] : process.env.REACT_APP_1
                          }
                        }
                      }).then((R)=>{
                        if(!R.data.request){
                          setError(R.data.response);
                          return null
                        }
                        setLoad(R.data.response.Item.Image)

                      }).catch((error)=>{
                        setError("Failed Retrieving Image!");
                        return null
                      })
        return null
        }catch(error){
            setError("Failed to Retrieve Image!");
            return null
        }
      }
  return(
      <div>
            <div className="">
                    <div className="slogan">For best service, call for appointment</div>
                    <img src={Image} alt="" className="g_image1"/>
                    <a className="button_container" href="tel:+17577134879"> 
                      <div >
                        <h3>
                          <img className="img1" src={Image1} alt="Sorry, image could not be loaded" /> 
                          Click here
                        </h3>
                      </div>
                    </a>
                    <div className="gallery">
                        {
                        loaded?
                          <div className="img_container">
                            <img className="img" src={loaded} alt="Sorry, image could not be loaded" />
                            <div className="gallery_buttons">
                                <button className="b_ward btn" onClick={(e)=>{setImg(1); back(e)}}> {"<<<"} </button>
                                <button className="b_ward btn" onClick={(e)=>{b_ward(e);}}> {"<"} </button>
                                <p className="c_img"> {`${img}`} / {`${images.length}`} </p>
                                <button className="f_ward btn" onClick={(e)=>{f_ward(e)}}> {">"} </button>
                                <button className="f_ward btn" onClick={(e)=>{setImg(images.length); front(e)}}> {">>>"} </button>
                            </div>
                          </div>
                        :   <div className="img_container">
                                <Loading/>
                            </div>
                        }
                                {
                                    error?
                                    <div className="default_error">{error}</div>
                                    : <div className="error"></div>
                                }
                    </div>
                </div>
        </div>
  )
}
export default Gallery