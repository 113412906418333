import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Image from '../../images/IMG_3242.jpeg'
import Image1 from '../../images/IMG_3240.jpeg'
import Image2 from '../../images/IMG_4444.jpg'
import Image3 from '../../images/IMG_3542.jpeg'
import Image4 from '../../images/IMG_245353.jpeg'
import Image5 from '../../images/IMG_5555.jpeg'
import './style.css'
const Services = () =>{

    const [error, setError] = useState("")
    const [data, setData] = useState([])
    const [choice, setChoice] = useState(0)

    const [data0, setdata0] = useState("")
    const [data1, setdata1] = useState("")
    const [data2, setdata2] = useState("")
    const [data3, setdata3] = useState("")

  
    const request = axios
    const name3 = process.env.REACT_APP_Him
    const name4 = process.env.REACT_APP_Her
    const name5 = process.env.REACT_APP_Helicopter
    const name6 = process.env.REACT_APP_umWtfAreU
    const name9 = process.env.REACT_APP_Airplane

    const changeChoice = (e) =>{
        e.preventDefault()
        if(choice === ""){
            setChoice(0)
        }else{
            setChoice("")
        }
    }

    const Run1 = async() => {
        try{

            const run = await request.post(`${process.env.REACT_APP_A432}`,{
                [name3] : "",
                [name4] : process.env.REACT_APP_webKey,
                [name6] : {
                    [name9] : {
                      "data0" : process.env.REACT_APP_GT,
                      [name5] : process.env.REACT_APP_1,
                      "data2" : data0,
                      "data3" : data1,
                      "data4" : data2,
                      "data5" : data3
                    }
                }
            }).then((R)=>{
                if(R.data.request){
                    setError("")
                    setData(R.data.response)
                    return null
                }else{
                    setError(R.data.response)
                    return null
                }
            }).catch((error)=>{
                setError("Failed to Retrive Testimonials!")
                return null
            })

        }catch(err){
            setError("Failed to Retrive Testimonials!")
            return null
        }
    }

    const Run2 = async(e) =>{
        e.preventDefault()
        const validdata2 = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        if(data3.length > 500 || !validdata2.test(data2) || data0.length > 20 || data1.length > 20){
            setError("Check Values!")
            return null
        }
        try{
            const run = await request.post(`${process.env.REACT_APP_A432}`,{
                    [name3] : "",
                    [name4] : process.env.REACT_APP_webKey,
                    [name6] : {
                        [name9] : {
                          "data0" : process.env.REACT_APP_GT,
                          [name5] : process.env.REACT_APP_0,
                          "data2" : data3,
                          "data3" : data0,
                          "data4" : data1,
                          "data5" : data2
                        }
                    }
            }).then((R)=>{
                if(!R.data.request){
                    setError(R.data.response)
                    return null
                }
                setError("Submitted for Review!")
                setdata1("")
                setdata0("")
                setdata2("")
                setdata3("")
                return null
            })
        return null
        }catch(err){
            setError("Failed Submitting Testimonial!")
            return null
        }
    }
     
    useEffect(()=>{
        Run1()
    },[])
    return(
        <div>
                <div className="services_Container">
                    <h2>Services Provided</h2>    
                    <br />                
                    <div className="title"> <img src={Image4} alt="" className="image2"/><span className="title2">Hairwash</span></div>
                    <div className="title"><img src={Image3} alt="" className="image2"/> <span className="title2">Varied Barber Services</span></div>                    
                    <div className="title"><img src={Image} alt="" className="image2"/><span className="title2">Razor Shaves</span></div>
                    <div className="title"><img src={Image1} alt=""  className="image2"/><span className="title2">Facial</span></div>
                    
                    <button className="before_after" onClick={(e)=>changeChoice(e)}>
                    {
                        choice === 0?
                        <div className="title"><img src={Image2} alt=""  className="image4"/><span className="title2">Loc Mx Before</span></div>
                        : <div className="title"><img src={Image5} alt=""  className="image4"/><span className="title2">Loc Mx After</span></div>
                        }
                        <p className="click">Click me</p>
                    </button>
                </div>

                <div className="customer_endorsement">Customer Testimonials</div>  {
                                    error?
                                    <div className="default_error">{error}</div>
                                    : <div className="error"></div>
                                }
                <div className="testimonials">       
                   {
                    data.map((Item, id)=>{
                        return(
                            <div key={id}>
                                <div className="data3">
                                    <div className="data3_content">
                                        <p>
                                            {Item.Item.Message}
                                        </p>
                                    </div>
                                    <div className="author_name">
                                            {Item.Item.FirstName}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                   }
                </div>

                <div className="customer_endorsement">Submit Testimonials</div>
                <div className="customer_create">
                    
                    <div className="data3_box">
                        <textarea name="" placeholder="Message" id="" cols="40" rows="10" onChange={(e)=>{setdata3(e.target.value)}} value={data3}></textarea>
                    </div>
                    <div className="name_input">
                        <input type="text" placeholder="First name" onChange={(e)=>{setdata0(e.target.value)}} value={data0}/>
                        <br />
                        <input type="text" placeholder="Last name" onChange={(e)=>{setdata1(e.target.value)}} value={data1}/>
                        <br />
                        <input type="text" placeholder="E-mail" onChange={(e)=>{setdata2(e.target.value)}} value={data2}/>
                    </div>
                    <div>
                    {
                                    error?
                                    <div className="default_error">{error}</div>
                                    : <div className="error"></div>
                                }
                        <button className="submit_btn" onClick={(e)=>{Run2(e)}}>Submit</button>
                    </div>
                </div>  

        </div>
    )
}

export default Services