import './links.css'

import Image1 from '../../images/666EF694-69BF-4114-A2EB-A0FDC35E5CDE_1_201_a.png'
const About = () => {

    return(
        <div className="about_con">
                <h2 className="about_header">About Spruce's Cutting Corner L.L.C</h2>
                <div className="about_2">
                    <div className="about_container">
                        <div className="about_sum">
                            <h3>John L. Spruce</h3>
                            John L. Spruce has been a barber since 1984. 
                            He truly enjoys sharing his gift and energy with his clients and all who sit in his chair. 
                            His core belief is "It's the skills that pay the bills." He often quotes his slogan "Haircuts, Fades, and Styles for Man, Woman, and Child" meaning to be of service to the afformentioned is to provide service to the world. Proof is in the pudding, but love is in the details. Book your appointment soon.
                        </div>
                    </div>
                    <img src={Image1} alt="" className="image1_about"/>
                </div>
                <center>
                    <br />
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Xe1WTqrDrVg?si=6-d-XdBKCvQWdHpg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </center>
        </div>
    )
}

export default About